import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { APConfirmDelete, APLightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { HomeworkModel } from "@/models";
import { TeCommon } from "../Common";

export class SearchForm {
    date = '';
    tid = '';
    clid = '';
    sid = '';
    cid = '';
    active = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherHomeworkPaperController>({
    components: {
        Header, APLightbox, APConfirmDelete,Footer
    }
})
export default class ApTeacherHomeworkPaperController extends Vue {
    private doubleClick = false;
    private searchForm = new SearchForm();
    private cityList: object = {};
    private schoolList: object = {};
    private classList: object = {};
    private teacherList: object = {};
    private paperList: object = {};
    private num = 0;
    private page = 1;
    private perpage = 50;
    private maxPage = 0;
    private hid = 0;
    private tid = window.localStorage.getItem('teacher_tid') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    public async created() {
        this.getPaperList();
        this.cityList = await TeCommon.getTECity(this.tid);//取得該老師所屬班級的所有縣市
    }

    //取得該老師所屬班級之該縣市所屬分校
    private async getTESchool() {
        this.searchForm.sid = '';
        this.searchForm.clid = '';
        this.searchForm.tid = '';

        const cid = this.searchForm.cid;
        this.schoolList = await TeCommon.getTESchool(cid, this.tid);
        this.classList = [];
        this.teacherList = [];
    }

    // 取得該老師所屬班級之該分校的班級列表
    public async getTEClass() {
        this.searchForm.clid = '';
        this.searchForm.tid = '';

        const sid = this.searchForm.sid;
        this.classList = await TeCommon.getTEClass(sid, this.tid);
        this.teacherList = [];
    }

    // 取得 該班級的老師列表
    public async getTeacher() {
        this.searchForm.tid = '';
        const clid = this.searchForm.clid;
        this.teacherList = await TeCommon.getTeacher(2, clid, 0);
    }

    public async getPaperList() {
        const item = {
            page: this.page,
            perpage: this.perpage,
            date: this.searchForm.date,
            tid: this.searchForm.tid,
            clid: this.searchForm.clid,
            sid: this.searchForm.sid,
            cid: this.searchForm.cid,
            active: this.searchForm.active,
            teacher: this.tid,
            token: this.token,
        }
        const data = await HomeworkModel.teGetPaperList(item) as response;
        TeCommon.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.paperList = data.list as object;
        this.maxPage = Math.ceil(this.num/this.perpage);
    }

    private getPageList(page: number) {
        this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
        this.getPaperList();
    }

    public async delPaper(id: number) {
        const item = {
            hid: id,
            token: this.token,
        };
        const errNo = await HomeworkModel.teCheckPaper(item);
        switch (errNo) {
            case ErrorCode.InvalidToken:
                TeCommon.logout();
                break;
            case 3:
                this.$emit("openConfirmDelete", true, '此作業已有學生填寫，確認刪除?');
                break;
            default:
                this.$emit("openConfirmDelete", true);
                break;
        }
        this.hid = id;
    }

    public async deletePaper() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const item = {
                hid: this.hid,
                token: this.token,
            }
            const errNo = await HomeworkModel.teDelPaper(item);
            this.$emit("closeConfirmDelete", false);
            switch (errNo) {
                case ErrorCode.InvalidToken:
                    TeCommon.logout();
                    break;
                case ErrorCode.Success:
                    this.doubleClick = false;
                    this.$router.go(0); //reload page
                    break;
                default:
                    this.lightbox.errorMsg = ErrorMessage[errNo];
                    this.lightbox.showLB = true;
                    this.doubleClick = false;
                    break;
            }
        }
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}